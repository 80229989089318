/** @type {Array<ListInfo>} */
const LIST = [
  {
    title: 'Explosive (Mudah meledak)',
    desc:
          "<ol style='margin-left: 20px;'><li>Amunisi</li><li>Fireworks (Kembang api)</li><li>Flare</li><li>Sekring</li><li>Primer</li><li>Detonator</li><li>Inflator</li><li>Rocker </li><li>Komposisi TNT</li><li>RDX</li></ol>",
    img: 'dangerous_goods/ics_f_flame.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Flammable Liquids (Cairan yang Mudah Terbakar)',
    desc:
          "<ol style='margin-left: 20px;'><li>Aseton/minyak aseton</li><li>Perekat</li><li>Cet/Pernis</li><li>Alkohol</li><li>Produk wewangian</li><li>Bensin</li><li>Solar</li><li>Avtur</li><li>Bio-bahan bakar cair</li></ol>",
    img: 'dangerous_goods/ics_f_paint_bucket.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Oxidizing Substances, Organic, Peroxides (Bahan Oksidasi, Peroksida, Organik)',
    desc:
          "<ol style='margin-left: 20px;'><li>Kimia generator oksigen</li><li>Pupuk Amonium nitrat</li><li>Klorat</li><li>Nitrat</li><li>Nitrit</li><li>Perchlorates</li><li>Permanganates</li><li>Persulphates</li><li>Aluminium nitrat</li><li>Amonium dikromat</li></ol>",
    img: 'dangerous_goods/ics_f_cassette.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Radioactive Material (Bahan Radioaktif)',
    desc:
          "<ol style='margin-left: 20px;'><li>Biji radioaktif</li><li>Isotop medis</li><li>Yellowcake</li><li>Produk fisi campuran</li><li>Cesium radiounuklida/isotop</li><li>Iridium radiounuklida/isotop</li><li>Amerisium radionuklida/isotop</li><li>Plutonium radionuklida/isotop</li></ol>",
    img: 'dangerous_goods/ics_f_cpu.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Miscellaneous Dangerous Goods (Bahan Berbahayan Lain-Lain)',
    desc:
          "<ol style='margin-left: 20px;'><li>Polimer manik-manik/manik-manik polistiren</li><li>Biru asbes/crocidolite</li><li>Baterai lithium ion</li><li>Baterai lithium metal</li><li>Peralatan bertenaga baterai</li></ol>",
    img: 'dangerous_goods/ics_f_warning_square.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Gases (Gas)',
    desc:
          "<ol style='margin-left: 20px;'><li>Aerosol</li><li>Perangkat bertenaga gas hidrokarbon</li><li>Alat pemadam kebakaran</li><li>Kartrid gas</li><li>Gas insektisida</li><li>Gas Refrigerant</li><li>Korek Api Gas</li><li>Acetylene/asetilin</li></ol>",
    img: 'dangerous_goods/ics_f_gas_station.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Flammable Solid (Bahan Padat Mudah Terbakar)',
    desc:
          "<ol style='margin-left: 20px;'><li>Logam alkali</li><li>Serbuk logam</li><li>Aluminium phosphide</li><li>Baterai natrium</li><li>Sel natrium</li><li>Firefighters</li><li>Korek Api Kayu</li><li>Kalsium karbida</li><li>Kapur barus</li><li>Karbon</li></ol>",
    img: 'dangerous_goods/ics_f_battery_full.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Toxic Substances, infectious Substances (Bahan Beracun, Bahan Infeksi)',
    desc:
          "<ol style='margin-left: 20px;'><li>Medis/limbah biomedis</li><li>Limbah klinis</li><li>Biologi budaya/sampel/spesimen</li><li>Medis budaya/sampel/spesimen</li><li>Zat gas air mata</li><li>Karbamat pestisida</ol>",
    img: 'dangerous_goods/ics_f_warning_triangles.svg',
    icoBgColor: '#FDEAE8',
  },
  {
    title: 'Corrosives (Karat/Korosif)',
    desc:
          "<ol style='margin-left: 20px;'><li>Asam solusi/asam</li><li>Aki</li><li>Cairan baterai</li><li>Kartrid sel bahan bakar</li><li>Chloride</li><li>Flux</li><li>Alkifenol</li></ol>",
    img: 'dangerous_goods/ics_f_forbidden.svg',
    icoBgColor: '#FDEAE8',
  },
];

module.exports = { LIST };
