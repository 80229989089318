/**
 *
 * @param array {[]}
 * @param perChunk {number}
 * @returns {[][]}
 */
const chunkArray = (array, perChunk) => array.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index / perChunk);

  if (!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [];
  }

  resultArray[chunkIndex].push(item);

  return resultArray;
}, []);

module.exports = { chunkArray };
