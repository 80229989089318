<template>
  <div class="dangerous-goods-section">
    <BreadCrumb class="top-left" />

    <!-- About Info -->
    <div class="dangerous-goods-section__info do-flex">
      <div class="dangerous-goods-section__info__text do-flex-col">
        <div class="dangerous-goods-section__info__text__title">
          Apa itu Dangerous Goods?
        </div>
        <div class="dangerous-goods-section__info__text__desc">
          Dangerous Goods adalah benda atau zat yang berisiko membahayakan kesehatan, keselamatan, aset dan lingkungan sehingga ada kemungkinan barang gagal terkirim.
        </div>
      </div>
      <ImageLazy
        :src="imgSrcIll"
        class="dangerous-goods-section__info__img"
        alt="dangerous-goods_info.png"
      />
    </div>
    <!-- About Info -->

    <!-- Collapse -->
    <div class="wrapper">
      <div class="wrapper-title">
        Berikut adalah ketentuan barang-barang yang tidak dapat terkirim dan dapat dikirim melalui Lion Parcel
      </div>
      <div class="wrapper-list">
        <div
          v-for="(lists, index) in listChunk"
          :key="index"
          class="coat-list"
        >
          <div
            v-for="(list, index2) in lists"
            id="list"
            :key="list.title"
          >
            <CardCollapse
              :index="(index * perChunk) + index2"
              :title="list.title"
              :sub-title="list.desc"
              :icon="getIcon(list.img)"
              :background-icon="list.icoBgColor"
              border-top
              expand-disappear-sub
              class="item-collapse"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Collapse -->

    <!-- Sub -->
    <div class="wrapper">
      <div class="wrapper-title">
        Barang-barang kategori “Dangerous Goods” yang dapat dikirimkan melalui Lion Parcel
      </div>
      <div class="wrapper-sub">
        <div class="sub-title">
          <div
            style="background-color: #E3FCEF"
            class="circle-icon"
          >
            <img
              :src="`/images/dangerous_goods/ics_f_shield check.svg`"
              class="icon-title"
              alt="icon"
            >
          </div>
          <h2
            class="head-title"
            v-html="CONTENT.CONTENT.title"
          />
        </div>
        <div
          class="sub-desc"
          v-html="CONTENT.CONTENT.desc"
        />
      </div>
    </div>
    <!-- Sub -->

    <!-- Video -->
    <div class="dangerous-goods-section__video do-flex-col">
      <p class="dangerous-goods-section__video__title-video">
        Video Seputar Dangerous Goods
      </p>
      <div class="dangerous-goods-section__video__wrapper-video">
        <div class="dangerous-goods-section__video__wrapper-video__thumbnail-youtube">
          <iframe
            allowfullscreen=""
            player="html5"
            style="border: none; overflow: hidden;"
            src="https://www.youtube.com/embed/kUCvKYJxQ4o"
            title=""
          />
        </div>
        <div class="dangerous-goods-section__video__wrapper-video__thumbnail-youtube">
          <iframe
            allowfullscreen=""
            player="html5"
            style="border: none; overflow: hidden;"
            src="https://www.youtube.com/embed/-Qqtg5qarKM"
            title=""
          />
        </div>
      </div>
    </div>
    <!-- Video -->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import mixinMobile from '../../misc/mixinMobile';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import LIST from './app/constants/list';
import { chunkArray } from './app/util/chunkArray';
import CardCollapse from '@/components/new-card-collapse';
import CONTENT from './app/constants/content';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'DangerousGoods',
  components: { BreadCrumb, CardCollapse, ImageLazy },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_dangerous_goods', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      LIST,
      list: [],
      perChunk: 5,
      CONTENT,
    };
  },
  computed: {
    imgSrcIll() {
      return this.windowWidth <= 599
        ? '/images/dangerous_goods/ill_dangerous_goods_mobile.svg'
        : '/images/dangerous_goods/ill_dangerous_goods.svg';
    },
    listChunk() {
      const list = this.LIST;
      const data = list.LIST;
      return chunkArray(data, this.perChunk);
    },
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    getIcon(src) {
      return `/images/${src}`;
    },
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
