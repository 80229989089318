<template>
  <div class="wrapper-card-collapse">
    <div
      v-if="title"
      class="section-title"
    >
      <div class="wrapper-title-card">
        <div
          v-if="icon"
          class="circle-icon"
          :style="`background-color: ${backgroundIcon}`"
        >
          <img
            :src="icon"
            class="icon-title"
            alt="icon"
          >
        </div>
        <h2 class="title-card">
          {{ title }}
        </h2>
      </div>
      <div
        v-if="!expandDisappear"
        class="expand-icon pointer"
        @click="() => onExpand()"
      >
        <img
          :src="`/images/chevron_down.svg`"
          class="arrow-down-icon"
          alt="arrow down"
        >
      </div>
      <div
        v-if="expandDisappear"
        class="expand-icon"
      />
    </div>
    <div
      :class="['section-desc', {
        'is-actived': isExpand,
        'border-top': borderTop
      }]"
    >
      <div
        v-show="subTitle"
        class="section-subtitle"
      >
        <h3
          v-if="!expandDisappearSub"
          class="subtitle"
          v-html="subTitle"
        />
        <p
          v-if="expandDisappearSub"
          v-html="subTitle"
        />
        <div
          v-if="!expandDisappearSub"
          class="expand-desc-icon"
          @click="() => onExpandDesc()"
        >
          <img
            :src="`/images/chevron_down.svg`"
            class="arrow-down-icon"
            alt="arrow down"
          >
        </div>
        <div
          v-if="expandDisappearSub"
          class="expand-desc-icon"
        />
      </div>
      <p
        :class="['desc-expand', { 'is-actived': isExpandDesc }]"
        v-html="description"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCollapse',
  props: {
    title: {
      type: String,
      // required: true,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      // required: true,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
    isExpandDesc: {
      type: Boolean,
      default: false,
    },
    expandDisappear: {
      type: Boolean,
      default: false,
    },
    expandDisappearSub: {
      type: Boolean,
      default: false,
    },
    borderTop: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    backgroundIcon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: 'is-opened',
      isActive: 'is-actived',
    };
  },
  methods: {
    onExpand() {
      if (!this.isSsr()) {
        const sectionDesc = document.querySelectorAll('.section-desc');
        const expandIcon = document.querySelectorAll('.expand-icon');
        sectionDesc[this.index].classList.toggle(this.isActive);
        expandIcon[this.index].classList.toggle(this.isOpen);
      }
    },
    onExpandDesc() {
      if (!this.isSsr()) {
        const descExpand = document.querySelectorAll('.desc-expand');
        const expandIcon = document.querySelectorAll('.expand-desc-icon');
        expandIcon[this.index].classList.toggle(this.isOpen);
        descExpand[this.index].classList.toggle(this.isActive);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
